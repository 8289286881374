import Model from '../interface/Model'

export default class OrderDocumentLine extends Model {
  public id: string = ''

  public start_at: string = ''

  public start_time: string = ''

  public end_at: string = ''

  public end_time: string = ''

  public month: string = ''

  public impressions: number = 0

  public geo_targeting: string = ''

  public zipcodes: string[] = []

  public notes: string = ''
}
