import AOSParser from './Drivers/AOSParser'
import OperativeParser from './Drivers/OperativeParser'
import OrderDocumentParserInterface from './Drivers/OrderDocumentParserInterface'

export default class OrderDocumentParser {
  public static parse(orderDocument: any): OrderDocumentParserInterface {
    let driver: OrderDocumentParserInterface = OperativeParser.parse(orderDocument)

    if (driver.isValidDocument()) {
      return driver
    }

    driver = AOSParser.parse(orderDocument)

    if (driver.isValidDocument()) {
      return driver
    }

    throw new Error('Invalid order document')
  }
}
