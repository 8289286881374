import moment from 'moment'
import { WorkBook, utils } from 'xlsx'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import OrderDocumentLine from '../OrderDocumentLine'
import OrderDocumentParserInterface from './OrderDocumentParserInterface'

export default class AOSParser implements OrderDocumentParserInterface {
  public data: any = null

  public static parse(orderDocument: any): OrderDocumentParserInterface {
    const instance = new this()

    instance.setData(orderDocument)

    return instance
  }

  public setData(orderDocument: any): this {
    this.data = orderDocument

    return this
  }

  public isValidDocument(): boolean {
    if (this.data[0]['Workstream ID'] && !this.data[0]['Additional Info']) {
      return true
    }

    return false
  }

  public isAgency(agency: string): boolean {
    return this.data[0].Product.includes(agency)
  }

  public get station(): string {
    return this.data[0].stationplanline_DealLine ?? ''
  }

  public get advertiser(): string {
    return this.data[0].Advertiser ?? ''
  }

  public get product(): string {
    return this.data[0].Product ?? ''
  }

  public get estimate_id(): string {
    return this.data[0]['Deal ID'] ?? ''
  }

  public get order_id(): string {
    return this.data[0]['Order ID'] ?? ''
  }

  public get geo_targeting(): string {
    let geo_targeting = ''
    let match = this.data[0].Geotarget.match(/(\d{5})/gims)
    if (match) {
      geo_targeting = 'Multiple Zip Codes'
    } else if (geo_targeting === '' && this.data[0].Geotarget.length > 50) {
      if (
        this.data[0].Geotarget.toLowerCase().includes('state')
        && !this.data[0].Geotarget.toLowerCase().includes('dma')
      ) {
        geo_targeting = 'Multiple States'
      } else if (
        !this.data[0].Geotarget.toLowerCase().includes('state')
        && this.data[0].Geotarget.toLowerCase().includes('dma')
      ) {
        geo_targeting = 'Multiple DMAs'
      } else {
        geo_targeting = 'Multiple Markets'
      }
    } else {
      geo_targeting = this.data[0].Geotarget
    }
    return geo_targeting
  }

  public get lines(): OrderDocumentLine[] {
    return this.data.map((row: any) => {
      let target = ''
      let zipcodes = []

      // Match Zip Code
      let match = row.Geotarget.match(/(\d{5})/gims)
      if (match) {
        zipcodes = match
        if (match.length > 10) {
          target = 'Multiple Zip Codes'
        } else {
          target = `Zips: ${match.join(', ')}`
        }
      } else if (target === '' && row.Geotarget.length > 50) {
        if (
          row.Geotarget.toLowerCase().includes('state')
          && !row.Geotarget.toLowerCase().includes('dma')
        ) {
          target = 'Multiple States'
        } else if (
          !row.Geotarget.toLowerCase().includes('state')
          && row.Geotarget.toLowerCase().includes('dma')
        ) {
          target = 'Multiple DMAs'
        } else {
          target = 'Multiple Markets'
        }
      } else {
        target = row.Geotarget
      }

      return OrderDocumentLine.toObject({
        id: row['Order Line ID'],
        start_at: moment(row['Start Date'], 'MM/DD/YYYY').format('YYYY-MM-DD'),
        start_time: moment(row['Start Time']).format('HH:mm:ss'),
        end_at: moment(row['End Date'], 'MM/DD/YYYY').format('YYYY-MM-DD'),
        end_time: moment(row['End Time']).format('HH:mm:ss'),
        month: moment(row['Start Date']).format('MMMM'),
        impressions: row['Production Quantity'],
        geo_targeting: target,
        zipcodes,
      })
    })
  }

  public toPdf(
    wb: WorkBook,
    file_name: string,
    user: string,
    order_name: string,
    cb: (result: Blob) => void,
  ) {
    let pdf_data = utils.sheet_to_json<any>(wb.Sheets[wb.SheetNames[0]], { header: 1 })

    let cols = pdf_data[0].length
    const keep_cols = [
      'Workstream Name',
      'Deal Name',
      'Deal ID',
      'Deal Line ID',
      'Advertiser',
      'Agency',
      'Start Date',
      'Start Time',
      'End Date',
      'End Time',
      'External System Timezone',
      'Assignee',
      'Sales Team',
      'Order ID',
      'Order Line ID',
      'Quantity',
      'Production Quantity',
      'Geotarget',
      'Order Type',
      'Advertiser Website',
    ]
    pdf_data = pdf_data.map((row, idx: number) => {
      let ret = []
      for (let i = 0; i < cols; i++) {
        let header = pdf_data[0][i]
        if (header.text) header = header.text

        let fillColor = 'balck'

        if (idx > 0) {
          fillColor = idx % 2 > 0 ? 'white' : '#e6e6e6'
        }
        let props = {
          bold: idx == 0,
          style: idx == 0 ? 'tableHeader' : 'tableBody',
          fillColor,
        }
        let text = row[i]
        if (!row[i]) {
          text = ''
        }

        row[i] = { text, ...props }

        if (keep_cols.includes(header)) {
          ret.push(row[i])
        }
      }
      return ret
    })

    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    const pdf_content: any = {
      pageOrientation: 'landscape',
      footer: {
        columns: [
          {
            text: `Auto generated from AOS Order File: ${file_name} | Uploaded By: ${user} | ${now}`,
            style: 'footer',
          },
        ],
      },
      content: [
        { text: order_name, style: 'header' },
        { text: '\n' },
        {
          table: {
            headerRows: 1,
            widths: pdf_data[0].map((cell: any) => 'auto'),
            body: pdf_data,
          },
        },
      ],
      styles: {
        header: {
          fontSize: 8,
          bold: true,
          alignment: 'center',
        },
        footer: {
          fontSize: 3,
          alignment: 'right',
          paddingRight: 5,
          marginRight: 5,
          right: 5,
        },
        tableHeader: {
          bold: true,
          fontSize: 2,
          color: 'white',
          fillColor: 'black',
          alignment: 'center',
          verticalAlignment: 'middle',
        },
        tableBody: {
          fontSize: 2,
          alignment: 'center',
        },
      },
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(pdf_content).getBlob((blob: Blob) => {
      cb(blob)
    })
  }
}
